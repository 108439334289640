import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_BlueIris_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "BlueIris v5 Configure Preset Positions",
  "path": "/Frequently_Asked_Question/BlueIris_v5_preset_positions/",
  "dateChanged": "2020-10-19",
  "author": "Mike Polinowski",
  "excerpt": "Hello, I have integrated my camera Blue Iris and would like to use the preset positions. The PTZ control via Blue Iris works completely and with all options, only the saved positions cannot be accessed.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras",
  "category": "smarthome",
  "type": "BlueIris"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='FAQs - BlueIris v5 Configure Preset Positions' dateChanged='2020-10-19' author='Mike Polinowski' tag='INSTAR IP Camera' description='Hello, I have integrated my camera Blue Iris and would like to use the preset positions. The PTZ control via Blue Iris works completely and with all options, only the saved positions cannot be accessed.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/BlueIris_v5_preset_positions/' locationFR='/fr/Frequently_Asked_Question/BlueIris_v5_preset_positions/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "blueiris-v5-configure-preset-positions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#blueiris-v5-configure-preset-positions",
        "aria-label": "blueiris v5 configure preset positions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`BlueIris v5 Configure Preset Positions`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: Hello, I have integrated the camera (firmware 4.2.2.20) via Onvif into `}<a parentName="p" {...{
        "href": "/en/Software/Windows/Blue_Iris_v5/"
      }}>{`Blue Iris (5.3.3.8)`}</a>{` and would like to use the preset positions. The PTZ control via Blue Iris works completely and with all options, only the saved positions cannot be accessed.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: To activate the preset functions first select the `}<strong parentName="p">{`INSTAR (NEW)`}</strong>{` profiles inside your cameras PTZ settings in BlueIris. Now edit those presets to the values `}<strong parentName="p">{`0-7`}</strong>{` for the first 8 preset positions:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0996b50642d6382eda0e40dca5f80813/9a1cf/BlueIris5_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADYElEQVQozzWSb2gTdxyHf026pl1frG/nKwXZkFgK6oQVfDGxAwtzL4SVEdNiNyirriZpaRC1k4IVp9VBWcdq+2orK1pn3Qbb6EatrTATI15Ykv7J5ZK7y+Uul/v7u/9N7jfS6fPq8+L78Lz5gp4vvhy4eOf02eFPPh8NDkQDZ8P9A0N3bt+e/nb65q2bQ2Pnhq4ORq8NB4KB7lPdY2NfTUxMTE5ORqPRrq4uMHIx+s3U1zduTVy9Nj42fmX0UnRq+vtkEnueSGAYtpHeiP8Tz6QymVQ6k05jGJZK/ZuIx2OxGI7joPP4pY7Tf+59L9zR/u6+fXu9noYLF4Zc12VZVtc1zTJ4SdQs065WZQg103QRsh1b0zTLssGhwz1HOz9+x//+wfZ2v/9gW1tbJBJxHIcgiCJNERjGbG2pRUamKIWmhQKpFou2JJmmads2OHT8/JHR+ycjU50fdB84cuKttw9EhkcQQpIi27peXP47t/wXu/5UjieU5y/gSwzGXwhYskCShmEA/0ehjt4bH34WPnaq92j3wJ6Ok5GR0bosSZZlZQliC8dFRYGGkcvnc7kcw7L/D13XQUtLqxcAb4PH0+hran4TABAKhRBClQoPoWqZpmPbruvuOI5ZrRoIWba94ziiKEIIgc/nAwB4PB6v19PU9AYAIBwOI4QoihYEwTAMXdc1CA3X1Z7FjehlJV8o8XylUrEsC/h8zaBOAwCgsbERADB47jxCiGZK9ROeN01TVWQJqgKWtH+Y10qlEscpslSrVUFzSwsAYL//8GBkvK8/3NMXunz9u/u/rf+09Hjh0erc4uO7i6uzi6t3763MPFyf+SMx+/Pa3IMnsw+ezNxbAa2trQCA3r7+p8/IuUcvf/k9ubCGz60V5mPsfIxNZpl8gSQK9Daep2iGphiKLlFFliqyNMO9ks98emYtRi6ktIfLqR9XNpY2jKU0/HXTwBlBlUWaprLb26IgWJapaRCqqqZBVVVelwNBJAn176EKCl1ACLmuixASRZHneabIkCTJMAxfqUAIFUWRZFmUpFdyMBCschUHIZjPU7m846KdWq2GkAohx7KZTHoru01S5ObmJklR2WyWIAhRFF+Xg32IK1cVxcBz5SJTj+5i27YgCNwuLMeWy2WO48ocJ+/yH0BNaR6K2XDBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0996b50642d6382eda0e40dca5f80813/e4706/BlueIris5_01.avif 230w", "/en/static/0996b50642d6382eda0e40dca5f80813/d1af7/BlueIris5_01.avif 460w", "/en/static/0996b50642d6382eda0e40dca5f80813/7f308/BlueIris5_01.avif 920w", "/en/static/0996b50642d6382eda0e40dca5f80813/e89ec/BlueIris5_01.avif 924w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0996b50642d6382eda0e40dca5f80813/a0b58/BlueIris5_01.webp 230w", "/en/static/0996b50642d6382eda0e40dca5f80813/bc10c/BlueIris5_01.webp 460w", "/en/static/0996b50642d6382eda0e40dca5f80813/966d8/BlueIris5_01.webp 920w", "/en/static/0996b50642d6382eda0e40dca5f80813/c3b05/BlueIris5_01.webp 924w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0996b50642d6382eda0e40dca5f80813/81c8e/BlueIris5_01.png 230w", "/en/static/0996b50642d6382eda0e40dca5f80813/08a84/BlueIris5_01.png 460w", "/en/static/0996b50642d6382eda0e40dca5f80813/c0255/BlueIris5_01.png 920w", "/en/static/0996b50642d6382eda0e40dca5f80813/9a1cf/BlueIris5_01.png 924w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0996b50642d6382eda0e40dca5f80813/c0255/BlueIris5_01.png",
              "alt": "BlueIris v5 Pan&Tilt Preset Positions",
              "title": "BlueIris v5 Pan&Tilt Preset Positions",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Save you settings, select your camera and try out the preset position buttons underneath your cameras live video:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "873px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b16db7f409b9fbc61f47d9baa3766bc3/35751/BlueIris5_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "21.304347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA1klEQVQI12Nwd3Fy9bG3t7WCI18vd3cXx+CggGg351J/36KQoBo/Xz83Z1sbS5gaS0d7G0tLcwb3gBi3oGgX30gX30hXvygX30iv4DjPoLig6PTQqLTm+p4pE+fUV7d7BsZA1ECRT4SlnRuDuJqltJaNhJqlpLqltIaVuJqllIaVhJqlhLqlpLqVkLKZtIaNiIq5pIaVlLqlhJqlnI6dkLyhiU+8e2IpAyMDAwsTIyMDAxMjCEEYUC4DAzMDAxsTKzMDIzNYkJGBgZWZiYGBQV7PyD22BACc9DFHGbpmVQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b16db7f409b9fbc61f47d9baa3766bc3/e4706/BlueIris5_02.avif 230w", "/en/static/b16db7f409b9fbc61f47d9baa3766bc3/d1af7/BlueIris5_02.avif 460w", "/en/static/b16db7f409b9fbc61f47d9baa3766bc3/7bc21/BlueIris5_02.avif 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b16db7f409b9fbc61f47d9baa3766bc3/a0b58/BlueIris5_02.webp 230w", "/en/static/b16db7f409b9fbc61f47d9baa3766bc3/bc10c/BlueIris5_02.webp 460w", "/en/static/b16db7f409b9fbc61f47d9baa3766bc3/d21af/BlueIris5_02.webp 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b16db7f409b9fbc61f47d9baa3766bc3/81c8e/BlueIris5_02.png 230w", "/en/static/b16db7f409b9fbc61f47d9baa3766bc3/08a84/BlueIris5_02.png 460w", "/en/static/b16db7f409b9fbc61f47d9baa3766bc3/35751/BlueIris5_02.png 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b16db7f409b9fbc61f47d9baa3766bc3/35751/BlueIris5_02.png",
              "alt": "BlueIris v5 Pan&Tilt Preset Positions",
              "title": "BlueIris v5 Pan&Tilt Preset Positions",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      